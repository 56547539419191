import React from "react"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import LearnButton from "../components/mainButton"
import CTA from "../components/cta"
import Layout from "../components/layout"
import SEO from "../components/seo"

const SewerServices = () => {
  const data = useStaticQuery(graphql`
    query sewerImages {
      sewers: file(relativePath: { eq: "sewer-repair.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      water: file(relativePath: { eq: "water-line-repair.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Sewer & Water Line Services"
        description={`Our certified team can handle your sewer or water line repair and installation. Call and receive a 100% free, no-strings-attached quote now.`}
      />
      <section>
        <div className="py-16 px-2 md:px-0">
          <h1 className="text-4xl text-center font-semibold text-blue-400 pb-16">
            Sewer and Water Line Services
          </h1>
          <div className="lg:flex">
            <div className="md:w-full lg:w-full md:mb-8">
              <div className="md:flex justify-center">
                <div className="md:w-1/2 lg:w-1/3 rounded overflow-hidden shadow-lg mb-8 md:mr-8 md:mb-0">
                  <Img
                    className="w-full"
                    fluid={data.sewers.childImageSharp.fluid}
                    alt="Sewer Line Repair"
                  />
                  <div className="px-3 py-4 md:px-6 md:py-8">
                    <h2 className="font-bold text-xl mb-4">Sewer Lines</h2>
                    <p className="text-gray-600 text-base mb-4">
                      If you need old sewer lines repaired/replaced or new ones
                      installed, we're able to help. Our process is fast and
                      painless to get your home up and running.
                    </p>
                    <LearnButton link={"/sewers/sewer-line-repair"} />
                  </div>
                </div>
                <div className="md:w-1/2 lg:w-1/3 rounded overflow-hidden shadow-lg mb-8 md:mr-8 md:mb-0">
                  <Img
                    className="w-full"
                    fluid={data.water.childImageSharp.fluid}
                    alt="Water Line Repair"
                  />
                  <div className="px-3 py-4 md:px-6 md:py-8">
                    <h2 className="font-bold text-xl mb-4">Water Lines</h2>
                    <p className="text-gray-600 text-base mb-4">
                      A broken or leaking water line is a serious concern. Our
                      top-notch team can repair your water lines so you don't
                      have damage to your home or an expensive utility bill.
                    </p>
                    <LearnButton link={"/sewers/water-line-repair"} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CTA />
    </Layout>
  )
}

export default SewerServices
